import { ReactElement } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Logo from "../assets/images/reframe-logo-letters.svg";
import LogoDark from "../assets/images/reframe-logo-letters-dark.svg";
import { useRouter } from "next/router";
import Link from "next/link";
import { ON_BOARDING_SIDEBAR_PATHS, paths } from "@/routing/paths";
import { twMerge } from "tailwind-merge";
import { useRouteHistory } from "../context/RouteHistoryContext";
import classNames from "classnames";
import OnBoardingProgress from "../components/OnBoarding/OnBoardingProgress";
import { useOnBoardingStep } from "../context/OnBoardingStepContext";

interface INavBarLayoutProps {
  children: ReactElement;
  headerStyle?: string; //passed as a Tailwind css background color property
  childrenContainerStyles?: string; //passed as a Tailwind css properties
  logoDark?: boolean;
  includeTopPadding?: boolean;
  displayXMark?: boolean;
  fixedHeight?: boolean;
  fixedContainerHeight?: boolean;
}

const NavBarLayout = ({
  children,
  headerStyle,
  childrenContainerStyles,
  logoDark = true,
  includeTopPadding = true,
  displayXMark = true,
  fixedHeight = false,
  fixedContainerHeight = false,
}: INavBarLayoutProps) => {
  const router = useRouter();
  const { backPathname, setBackPathname } = useRouteHistory();

  const { onBoardingStep } = useOnBoardingStep();

  const isOnOnBoardingPage = ON_BOARDING_SIDEBAR_PATHS.some((p) =>
    router.pathname.includes(p)
  );

  return (
    <>
      <div
        className={`relative flex flex-row justify-between items-center px-5 md:px-16 w-full h-16 ${twMerge(
          "bg-white shadow-md",
          headerStyle
        )} z-50`}
      >
        <Link href={paths.root.index()}>
          <a
            className={classNames({
              "mb-4 md:mb-0": isOnOnBoardingPage,
            })}
          >
            {logoDark ? <LogoDark /> : <Logo />}
          </a>
        </Link>

        {displayXMark && (
          <XMarkIcon
            height={30}
            width={30}
            color={logoDark ? "black" : "white"}
            className="cursor-pointer"
            onClick={() => {
              if (backPathname) {
                router.push(backPathname);
                setBackPathname && setBackPathname(undefined);
              } else router.back();
            }}
          />
        )}
      </div>
      {isOnOnBoardingPage && (
        <div className="relative md:hidden flex mt-[-16px] z-50">
          <OnBoardingProgress
            currentStep={onBoardingStep}
            orientation="horizontal"
          />
        </div>
      )}
      <div
        className={classNames("flex", {
          "h-[calc(100%-64px)]": fixedContainerHeight,
          "min-h-[calc(100%-64px)]": !fixedContainerHeight,
        })}
      >
        {isOnOnBoardingPage && (
          <div className="hidden md:inline-block relative w-1/4 p-8 bg-white z-40 h-[calc(100vh-64px)]">
            <OnBoardingProgress
              currentStep={onBoardingStep}
              orientation="vertical"
            />
          </div>
        )}
        <div
          className={classNames(
            `${childrenContainerStyles} flex flex-wrap justify-center my-0 mx-auto py-0 px-4 md:px-0 flex-1
        ${!childrenContainerStyles?.includes("max-w") ? "max-w-5xl" : ""}`,
            {
              "md:py-2": includeTopPadding,
              "h-[calc(100vh-64px)]": fixedHeight,
            }
          )}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default NavBarLayout;
