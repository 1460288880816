import { BROKER_DETAILS_KEY } from "@/services/user";
import {
  InMemoryCache,
  ApolloClient,
  ApolloLink,
  Observable,
  from,
  Operation,
  FetchResult,
} from "@apollo/client";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import {
  getAgnesAccessToken,
  getIdToken,
  getStoredTokens,
  signOut,
} from "./services/auth";

export const refreshTokenMiddleware = onError(
  ({ graphQLErrors, operation, forward }: ErrorResponse) => {
    const unauthorized = !!graphQLErrors?.find(
      (error) => error.message === "NOT_AUTHORIZED"
    );

    const retryOperation = !!operation.getContext()._retryOperation;

    if (unauthorized && !retryOperation) {
      return new Observable((observer) => {
        getStoredTokens().then((tokens) => {
          if (!tokens) {
            signOut();
            window.location.assign(
              "/signin?redirectTo=" +
                encodeURIComponent(window.location.pathname)
            );
          }

          operation.setContext(({ headers = {} }: any) => ({
            headers: {
              ...headers,
              ...(tokens?.accessToken &&
                tokens.idToken && {
                  Authorization: `Bearer ${tokens.accessToken}`,
                  "id-token": tokens.idToken,
                }),
            },
            _retryOperation: true,
          }));

          const subscription = forward(operation).subscribe(observer);

          return () => {
            if (subscription) {
              subscription.unsubscribe();
            }
          };
        });
      });
    }

    if (unauthorized && retryOperation) {
      signOut();
      window.location.reload();
    }
  }
);

const authMiddleware = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    let handle: any = null;
    let agnesAccessToken: string, refreshToken: string;
    getAgnesAccessToken()
      .then((token) => {
        agnesAccessToken = token;
        return getIdToken();
      })
      .then((idToken) => {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            ...(agnesAccessToken && {
              Authorization: `Bearer ${agnesAccessToken}`,
            }),

            "id-token": idToken,
          },
        }));

        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) {
        handle.unsubscribe();
      }
    };
  });
});

const saveResponseToSessionStorageForQuery =
  (queryName: string, itemName: string) =>
  (
    response: FetchResult<
      Record<string, any>,
      Record<string, any>,
      Record<string, any>
    >,
    operation: Operation
  ) => {
    if (operation.operationName === queryName) {
      const { data } = response;
      sessionStorage.setItem(itemName, JSON.stringify(data));
    }
  };

const brokerDetailsLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    saveResponseToSessionStorageForQuery(
      "GetBrokerDetails",
      BROKER_DETAILS_KEY
    )(response, operation);
    return response;
  });
});

const client = new ApolloClient({
  link: from([
    authMiddleware,
    refreshTokenMiddleware,
    brokerDetailsLink,
    createUploadLink({
      uri: process.env.NEXT_PUBLIC_AGNES_SERVICE_URL + "/graphql",
    }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Organization: {
        fields: {
          brokers: {
            merge(existing = [], incoming: any[]) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});

export default client;
