import jwtDecode from "jwt-decode";
import {
  ICreateBrokerRequest,
  IVerifyBrokerRequest,
} from "../queries/Organization";
import { SESSION_EMAIL_KEY } from "@/constants/storage";
import { BrokerRole } from "@/types/User";
import { RoleGroup } from "@/types/RoleGroup";
import { isServer } from "@/helpers/nextHelpers";
const TOKENS_KEY = "tokens";
const ALLOWED_GROUPS = ["brokers-group", "admin-group", "carriers-group"];

export interface signInData {
  user: string;
  password: string;
  rememberUser: boolean;
}

const getTokenDetails = () => {
  const tokens =
    sessionStorage.getItem(TOKENS_KEY) || localStorage.getItem(TOKENS_KEY);
  if (!tokens) return;

  return jwtDecode<any>(
    JSON.parse(tokens!).authentication.response.token.accessToken
  );
};

export const getUserName = () => {
  const storage = localStorage.getItem(TOKENS_KEY)
    ? localStorage
    : sessionStorage;

  return storage.getItem("user_name")!;
};

export const getUserId = () => {
  const storage = localStorage.getItem(TOKENS_KEY)
    ? localStorage
    : sessionStorage;

  return storage.getItem("user_oid")!;
};

export const getOrganizationId = () => {
  const storage = localStorage.getItem(TOKENS_KEY)
    ? localStorage
    : sessionStorage;

  return storage.getItem("organization_id")!;
};

export const setUserId = (userId: string) => {
  sessionStorage.setItem("user_oid", userId);
};

export const getBrokerRole = (): BrokerRole => {
  const storage = localStorage.getItem(TOKENS_KEY)
    ? localStorage
    : sessionStorage;

  const role = storage.getItem("role");
  return role ? JSON.parse(role) : "";
};

const groupsOrder = ["brokers-group", "admin-group", "insurer-group"];

export const signIn = async (data: signInData) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ user: data.user, password: data.password }),
  };
  const url = `${process.env.NEXT_PUBLIC_AGNES_SERVICE_URL}/signin`;
  try {
    let response = await fetch(url, options);

    if (response.status == 200) {
      const responseJSON = await response.json();

      clearStorage();
      const storage = data.rememberUser ? localStorage : sessionStorage;
      storage.setItem(TOKENS_KEY, JSON.stringify(responseJSON));

      const userId = getTokenDetails()?.sub;

      storage.setItem("user_oid", userId);

      storage.setItem(SESSION_EMAIL_KEY, data.user);

      const userName = getTokenDetails()?.username;

      storage.setItem("user_name", userName);

      const emailVerified = jwtDecode<any>(await getIdToken()).email_verified;

      const groups = getTokenDetails()["cognito:groups"];
      storage.setItem("groups", groups);

      const organizationId = responseJSON.userData.organizations[0];

      const { role } = responseJSON.userData;

      if (role) {
        storage.setItem("role", JSON.stringify(role));
      }

      if (organizationId) storage.setItem("organization_id", organizationId);
      groups.sort(
        (g1: string, g2: string) =>
          groupsOrder.indexOf(g1) - groupsOrder.indexOf(g2)
      );
      return {
        success: true,
        role: groups,
        allowedSignIn: groups.some((x: string) => ALLOWED_GROUPS.includes(x)),
        emailVerified,
      };
    } else {
      return { success: false, role: null };
    }
  } catch (err) {
    console.log("err: ", err);
    return { success: false, role: null };
  }
};

export const isInGroup = (roles: string[], target: string) => {
  return roles.find((role) => role === target);
};

export const signUp = async (
  data: ICreateBrokerRequest,
  isCarrier: boolean,
  googleRecaptchaToken: string
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      ...data,
      googleRecaptchaToken,
      group: isCarrier ? RoleGroup.Carrier : RoleGroup.Broker,
    }),
  };
  const url = `${process.env.NEXT_PUBLIC_AGNES_SERVICE_URL}/signup`;

  let response = await fetch(url, options);
  let responseJSON = await response.json();

  if (responseJSON.error === "INVALID_RECAPTCHA") {
    throw Error("Invalid recaptcha. Refresh the page and try again");
  } else if (responseJSON.authentication?.statusCode === 422) {
    if (
      responseJSON.authentication?.response?.code === "UsernameExistsException"
    ) {
      throw Error("The email already exists");
    }
    if (
      responseJSON.authentication?.response?.code === "InvalidPasswordException"
    ) {
      throw Error("Password does not meet the complexity requirements");
    }
  } else if (responseJSON.error) {
    throw Error("There was an error signing up");
  }
  if (!isCarrier) {
    setUserId(responseJSON.authentication.response.userSub);
    sessionStorage.setItem(
      "user_oid",
      responseJSON.authentication.response.userSub
    );
    sessionStorage.setItem(SESSION_EMAIL_KEY, data.input.mail);
  }
};

export const forgotPassword = async (email: string) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ email }),
  };

  const url = `${process.env.NEXT_PUBLIC_AGNES_SERVICE_URL}/forgot-password`;
  const response = await fetch(url, options);

  return response.ok;
};

export const confirmNewPassword = async (
  email: string,
  code: string,
  password: string
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ code, password, email }),
  };

  const url = `${process.env.NEXT_PUBLIC_AGNES_SERVICE_URL}/confirm-new-password`;
  const response = await fetch(url, options);

  return await response.json();
};

export const verify = async (
  data: IVerifyBrokerRequest,
  googleRecaptchaToken: string
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ ...data, googleRecaptchaToken }),
  };
  const url = `${process.env.NEXT_PUBLIC_AGNES_SERVICE_URL}/verify`;
  try {
    let response = await fetch(url, options);
    let responseJSON = await response.json();
    if (responseJSON.authentication.statusCode === 422) throw false;
    if (!responseJSON.error) {
      sessionStorage.setItem("user_oid", responseJSON);
      sessionStorage.setItem(SESSION_EMAIL_KEY, data.input.mail);
    }
    return responseJSON;
  } catch (err) {
    return false;
  }
};

const clearStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};

export const signOut = () => {
  clearStorage();
};

export const refreshTokens = async () => {
  const headers = new Headers();

  const tokens =
    sessionStorage.getItem(TOKENS_KEY) || localStorage.getItem(TOKENS_KEY);
  if (!tokens) return;

  const tokensObj = JSON.parse(tokens);
  const rememberUser = !!localStorage.getItem(TOKENS_KEY);

  headers.append("Content-Type", "application/json");
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      refreshToken: tokensObj.authentication.response.token.refreshToken,
    }),
  };

  const url = `${process.env.NEXT_PUBLIC_AGNES_SERVICE_URL}/refresh-tokens`;

  let response = await fetch(url, options);

  if (response.status == 200) {
    const responseJSON = await response.json();

    if (rememberUser) {
      localStorage.setItem(TOKENS_KEY, JSON.stringify(responseJSON));
    } else {
      sessionStorage.setItem(TOKENS_KEY, JSON.stringify(responseJSON));
    }
    return { success: true, role: responseJSON.role };
  } else {
    return { success: false, role: null };
  }
};

export const isLoggedIn = () => {
  if (isServer()) {
    return false;
  }
  return (
    !!sessionStorage.getItem(TOKENS_KEY) || !!localStorage.getItem(TOKENS_KEY)
  );
};

export const isUserRemembered = () => {
  if (isServer()) {
    return false;
  }
  return !!localStorage.getItem(TOKENS_KEY);
};

export const isSameIdAsLoggedIn = (oid: string) => {
  return getTokenDetails().sub === oid;
};

const areTokensExpired = () => {
  const currentTimestampInSeconds = Math.floor(new Date().valueOf() / 1000);

  const tokensObject =
    sessionStorage.getItem(TOKENS_KEY) || localStorage.getItem(TOKENS_KEY);
  if (!tokensObject) return;

  const tokens = JSON.parse(tokensObject);

  const expirationTimestamp = tokens.authentication.response.exp;
  return currentTimestampInSeconds > expirationTimestamp;
};

export const getStoredTokens = async () => {
  if (areTokensExpired()) {
    const res = await refreshTokens();
    if (!res?.success) {
      return;
    }
  }

  const tokens =
    sessionStorage.getItem(TOKENS_KEY) || localStorage.getItem(TOKENS_KEY);

  if (!tokens) return;

  const tokensObj = JSON.parse(tokens);
  const { accessToken, idToken } = tokensObj.authentication.response.token;

  return { accessToken, idToken };
};

export const getAgnesAccessToken = async () => {
  // if (areTokensExpired()) {
  //   await refreshTokens();
  // }

  const tokens =
    sessionStorage.getItem(TOKENS_KEY) || localStorage.getItem(TOKENS_KEY);

  if (!tokens) return;

  const tokensObj = JSON.parse(tokens);

  return tokensObj.authentication.response.token.accessToken;
};

export const getIdToken = async () => {
  // if (areTokensExpired()) {
  //   await refreshTokens();
  // }

  const tokens =
    sessionStorage.getItem(TOKENS_KEY) || localStorage.getItem(TOKENS_KEY);

  if (!tokens) return;

  const tokensObj = JSON.parse(tokens);
  return tokensObj.authentication.response.token.idToken;
};

export const getUserRole = (): RoleGroup | undefined => {
  const tokens =
    sessionStorage.getItem(TOKENS_KEY) || localStorage.getItem(TOKENS_KEY);

  if (!tokens) return;

  const tokensObj = getTokenDetails()["cognito:groups"];
  return tokensObj[0];
};
