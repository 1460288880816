import { useEffect, ReactNode, useState } from "react";
import { useRouter } from "next/router";
import { getUserId, getUserRole } from "../../services/auth";
import { ON_BOARDING_SIDEBAR_PATHS, paths } from "../../routing/paths";
import { useLazyQuery } from "@apollo/client";
import {
  IGetBrokerDetailsResponse,
  IGetBrokerDetailsRequest,
  GET_BROKER_DETAILS,
} from "@/queries/User";
import { useUserContext } from "@/context/UserContext";
import { publicRoutes } from "@/constants/publicRoutes";
import { RoleGroup } from "@/types/RoleGroup";

interface IOnBoardingGuardProps {
  children: ReactNode;
}

function OnBoardingGuard({ children }: IOnBoardingGuardProps) {
  const router = useRouter();

  const { setUser } = useUserContext();

  const [getBrokerDetails] = useLazyQuery<
    IGetBrokerDetailsResponse,
    IGetBrokerDetailsRequest
  >(GET_BROKER_DETAILS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (getUserRole() !== RoleGroup.Admin) {
      if (!publicRoutes.filter((route) => router.pathname.match(route)).length)
        getBrokerDetails({
          variables: {
            userId: getUserId(),
          },
        }).then((result) => {
          setUser(result.data?.getBrokerDetails);

          if (
            result.data?.getBrokerDetails &&
            !result.data.getBrokerDetails.isOnboardingCompleted
          ) {
            if (
              ON_BOARDING_SIDEBAR_PATHS.every(
                (p) => !router.pathname.includes(p)
              ) &&
              router.pathname !== paths.dashboard()
            )
              router.push(paths.onboarding.welcome());
          } else if (
            result.data?.getBrokerDetails.isOnboardingCompleted &&
            ON_BOARDING_SIDEBAR_PATHS.some((p) => router.pathname.includes(p))
          ) {
            router.push(paths.dashboard());
          }
        });
    }
  }, [router]);

  return <>{children}</>;
}

export default OnBoardingGuard;
